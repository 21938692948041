import React from "react";

import { BrowserRouter as Router } from "react-router-dom";

import "@fontsource/jetbrains-mono";
import "typeface-rubik";

import { CircularProgressCenter, ErrorView, ModeControllerProvider, useBuildModeController } from "@firecms/core";
import { useInitialiseFirebase } from "@firecms/cloud";

import { backendFirebaseConfig } from "./backend_firebase_config";
import { SaasAnalyticsProvider } from "./components/SaasAnalyticsProvider";
import { SaasApp } from "./SaasApp";

export function App() {

    const {
        firebaseApp: backendFirebaseApp,
        firebaseConfigLoading,
        configError,
        firebaseConfigError
    } = useInitialiseFirebase({
        name: "firecms-backend",
        firebaseConfig: process.env.NODE_ENV !== "production" ? backendFirebaseConfig : undefined,
        authDomain: backendFirebaseConfig.authDomain
    });

    const modeController = useBuildModeController();

    if (firebaseConfigLoading || !backendFirebaseApp) {
        return <CircularProgressCenter/>;
    }

    if (firebaseConfigError) {
        return <ErrorView
            error={firebaseConfigError}/>
    }

    if (configError) {
        return <ErrorView
            error={configError}/>
    }


    return (
        <Router>
            <SaasAnalyticsProvider backendFirebaseApp={backendFirebaseApp}>
                <ModeControllerProvider value={modeController}>
                    <SaasApp backendFirebaseApp={backendFirebaseApp}/>
                </ModeControllerProvider>
            </SaasAnalyticsProvider>
        </Router>
    );
}
