import { ProductPrice, ProLicense, SubscribeParams } from "@firecms/cloud";

export async function createLicenseSubscription(subscribe: (params: SubscribeParams) => Promise<() => void>, license: ProLicense, price: ProductPrice) {

    return subscribe({
        licenseId: license.id,
        productPrice: price,
        quantity: license.licensed_users,
        onCheckoutSessionReady: (url, error) => {
            if (!url && !error)
                return;
            if (error) {
                throw error;
            }
            if (url) {
                if (typeof window !== "undefined") {
                    window.location.assign(url);
                }
            }
        },
        type: "pro"
    });
}
