import { formatPrice, ProductPrice, TableCell, TableRow } from "@firecms/cloud";
import { Table, TableBody, TableHeader } from "@firecms/ui";

export function TierPricingTable({ price }: { price: ProductPrice }) {
    if (price.billing_scheme !== "tiered") {
        return null;
    }

    const tiers = price.tiers;
    return (
        <Table className="table-auto">
            <TableHeader>
                <th className="px-4 py-2">Users</th>
                <th className="px-4 py-2">Price</th>
            </TableHeader>
            <TableBody>
                {tiers.map((tier, index) => (
                    <TableRow key={index}>
                        {tier.flat_amount && <TableCell className=" px-4 py-2">Up to {tier.up_to} users</TableCell>}
                        {!tier.flat_amount && tier.up_to &&
                            <TableCell className=" px-4 py-2">Up to {tier.up_to} users</TableCell>}
                        {!tier.up_to && <TableCell className=" px-4 py-2">Rest of the users</TableCell>}
                        {tier.flat_amount &&
                            <TableCell className="px-4 py-2">{formatPrice(tier.flat_amount, price.currency)} flat</TableCell>}
                        {!tier.flat_amount &&
                            <TableCell className="px-4 py-2">{formatPrice(tier.unit_amount, price.currency)} per
                                user</TableCell>}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}
